@import "libs/theme/orion-theme";

@mixin search-result-theme($orion-theme) {
  o-search-result {
    mat-tab-group,
    .select-unselect-actions {
      background: background-color($orion-theme, background);
      color: color-link($orion-theme);
    }

    .header-search {
      background: background-color($orion-theme, background);
    }

    mat-chip:not(.search-chip) {
      color: text-with-background($orion-theme) !important;

      &.enabled {
        background: color-accent($orion-theme) !important;
      }

      &:not(.enabled) {
        background: foreground-rgb-color($orion-theme, 0.4) !important;
        color: foreground-rgb-color($orion-theme, 0.87);
      }
    }

    .mat-chip-remove {
      color: text-with-background($orion-theme) !important;
    }

    .add-to {
      background: adapt-color($orion-theme, $color-grey2);

      .addTo {
        color: color-link($orion-theme);

        a {
          background: adapt-color($orion-theme, $color-blk-12);
          border-radius: 3px;
          padding: 5px;

          mat-icon {
            vertical-align: text-bottom;
          }
        }
      }
    }

    .disabled {
      color: adapt-color($orion-theme, $color-grey4);
    }

    .information-result-container {
      .selection {
        color: color-link($orion-theme);
      }
    }

    .search-chip {
      box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
        0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
    }

    .search--export-menu-button,
    .search--export-selection,
    .search--export-all {
      &.disabled {
        ::ng-deep svg {
          fill: foreground-rgb-color($orion-theme, 0.3);
        }
      }
    }

    .mat-sidenav {
      background: background-color($orion-theme, background);
    }
  }
}
