@import "libs/theme/orion-theme";

@mixin ipc-details-theme($orion-theme) {
  o-ipc-detail {
    .ipc_card {
      background: background-color($orion-theme, background);
    }

    .header {
      background: color-accent($orion-theme);

      h1 {
        color: text-with-background($orion-theme);
      }

      .mat-badge-content {
        background: text-with-background($orion-theme);
        border: 1px solid color-accent($orion-theme) !important;
        bottom: -4px !important;
        color: color-accent($orion-theme) !important;
        font-size: 14px !important;
      }

      .is-in-basket .mat-badge-content {
        background: text-with-background($orion-theme);
        color: color-green($orion-theme) !important;
      }
    }

    .span_links {
      color: color-link($orion-theme);
    }

    .selected {
      background: background-color($orion-theme, hover);
    }

    .mounted-mountable-details {
      .value-box {
        box-shadow: 0 0 0 1px foreground-color($orion-theme, text);
      }
    }
  }

  .current::before {
    background: color-accent($orion-theme) !important;
  }

  .select-title {
    background: background-color($orion-theme, background);
    color: foreground-color($orion-theme, text);
  }
}
