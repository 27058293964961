@import "libs/theme/orion-theme";
@include mat-core();

@font-face {
  font-family: "roboto";
  src: url("../assets/font/Roboto-Regular.ttf");
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  color: $color-blk-8;
  font-family: Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  -moz-font-feature-settings: "liga" on;
  font-feature-settings: "liga" on;
  font-size: 18px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-weight: 400;
  height: 100%;
  letter-spacing: 0;
  line-height: 1.4;
  margin: 0;
  text-rendering: optimizeLegibility;
}

.mat-drawer-backdrop.mat-drawer-shown {
  background-color: transparent !important;
}

.search-part {
  .mat-input-underline {
    background-color: $color-white !important;
  }
}

mat-select {
  padding-top: 0 !important;
}

.mat-select-panel {
  mat-option {
    height: 2em !important;
  }
}

mat-calendar * {
  border: none !important;
}

.success-snackbar {
  background: $color-success-light !important;
  color: $color-success !important;
}

.warning-snackbar {
  background: $color-warn-light !important;
  color: $color-warn !important;
}

.error-snackbar {
  background: $color-err-light !important;
  color: $color-warn !important;
  white-space: pre-wrap;
}

.overflow {
  overflow: auto;
}

.sticky-bottom {
  bottom: 0;
}

.mat-dialog-container {
  position: relative;
}

.disclaimer {
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  right: 0;
  top: 0;

  > .mat-dialog-container {
    max-width: 600px;
  }
}
// add two classes for symbols
.symbol {
  display: inline-flex;
  height: 35px;
  margin: 5px 5px 0 0;
  vertical-align: text-top;
  width: 35px;

  //if symbol is svg
  svg {
    height: 35px;
    width: 35px;
  }
}

table {
  .symbol {
    margin: 5px;
  }
}

.step-text-container {
  display: inline;
  line-height: inherit;
  place-items: center;
  vertical-align: text-top;
}
// notes
.note--options {
  ::ng-deep.mat-option-text {
    align-items: center;
    display: flex;
  }

  .mat-dialog-container {
    max-height: 80vh;
  }
}

.media-infos {
  background: white;
  font-size: 0.6em;
  margin: auto 0;
  text-align: center;

  .figure-number {
    font-size: 0.95em;
  }

  div {
    color: black;
  }
}

.invisible {
  display: none !important;
}

.mat-card-header .mat-card-title {
  // Default value from angular material v6
  font-size: 14px;
}

.hidden {
  display: none !important;
}

.tranparent_background {
  background: transparent !important;
}

.flag-icon {
  height: 15px;
  width: 20px;
}

a[disabled] {
  pointer-events: none;
}

@media only screen {
  .marked-text,
  .highlight,
  .toolHightlight {
    background: $color-yellow !important;
    color: $color-blk-87 !important;

    * {
      background-color: $color-yellow !important;
      color: $color-blk-87 !important;
    }
  }
}

@media print {
  body {
    font-family: Sans-Serif;
    // Force print background on electron
    // https://github.com/electron/electron/issues/20583#issuecomment-591378308
    -webkit-print-color-adjust: exact;
  }

  o-media {
    box-shadow: none !important;
    page-break-inside: avoid;
  }

  o-media * {
    display: block !important;
    page-break-inside: avoid;
  }

  o-table {
    display: block !important;
    overflow: visible !important;
  }

  o-header {
    display: none !important;
  }

  o-thumbs {
    display: block;
    height: auto !important;
    page-break-inside: avoid;
  }

  .sticky {
    position: relative !important;
    top: unset !important;
  }

  table {
    overflow: visible !important;
  }

  .loeduDmcWidth {
    width: 21% !important;
  }

  thead {
    display: table-header-group;
  }

  tfoot {
    display: table-row-group;
  }

  tr {
    page-break-inside: avoid;
  }

  .media-title {
    background-color: white;
    color: black;
  }

  img {
    page-break-inside: avoid;
  }
}
