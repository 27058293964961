@import "libs/theme/orion-theme";

@mixin ipc-card-theme($orion-theme) {
  o-ipc-card {
    &.selected {
      .mat-card,
      .in-grid {
        background: background-color($orion-theme, hover);
      }
    }

    .in-grid {
      color: foreground-color($orion-theme, text);
    }

    .ipc--custom {
      color: color-red($orion-theme);
    }

    mat-card,
    .shop-col {
      .mat-badge-content {
        background: color-accent($orion-theme) !important;
        bottom: -4px !important;
        font-size: 14px !important;
      }

      .is-in-basket .mat-badge-content {
        background: color-green($orion-theme) !important;
      }
    }
  }
}
