@import "libs/theme/orion-theme";

@mixin orion-card-theme($orion-theme) {
  o-orion-card {
    &.selected-node .mat-card,
    mat-card:hover {
      background: background-color($orion-theme, hover);
    }

    .subtitle,
    .footer {
      color: foreground-rgb-color($orion-theme, 0.47);
    }

    .applic-action .applic-label {
      color: color-accent($orion-theme);
    }

    .impact-sb {
      color: color-red($orion-theme);
    }
  }
}
