@import "libs/theme/orion-theme";

@mixin modal-theme($orion-theme) {
  o-applic-modal,
  o-settings-form,
  o-historic-dialog,
  o-eordering-dialog,
  o-transfert-ti-dialog,
  o-web-tek-dialog,
  o-pym-modal,
  o-diagnostic-modal,
  o-contract-modal,
  o-info-dialog,
  o-add-news-modal,
  o-confirm-dialog,
  o-cm-preview,
  o-disclaimer,
  o-release-info,
  o-bookmark-edit,
  o-news-modal,
  o-ipc-preview,
  o-support-modal,
  o-tutorial-modal,
  o-training-modal {
    .icon {
      color: white;

      &:hover,
      &:focus {
        background: color-accent($orion-theme);
      }
    }

    .errorColor {
      color: color-red($orion-theme) !important;
    }
  }

  .cdk-overlay-pane-select-search {
    .mat-select-content {
      mat-option:first-of-type {
        background: background-color($orion-theme, matOptionFirst) !important;
      }
    }
  }

  ngx-mat-select-search {
    .mat-select-search-no-entries-found {
      background: background-color($orion-theme, background) !important;
      color: foreground-rgb-color($orion-theme, 0.47);
    }

    .mat-select-search-inner {
      input {
        background: background-color($orion-theme, background) !important;
        color: foreground-color($orion-theme, text);
      }

      ::-webkit-input-placeholder {
        background: background-color($orion-theme, background) !important;
        color: foreground-rgb-color($orion-theme, 0.47);
      }
    }
  }

  o-disclaimer,
  o-training-modal,
  o-support-modal {
    a,
    a * {
      color: color-link($orion-theme) !important;
    }
  }
}
