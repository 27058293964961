@use "@angular/material" as mat;
@import "libs/theme/orion-theme";
@include mat.legacy-core();

@mixin shared-theme($orion-theme) {
  // @include mat.all-legacy-component-themes($orion-theme);
  @include mat.legacy-autocomplete-theme($orion-theme);
  @include mat.legacy-button-theme($orion-theme);
  @include mat.legacy-card-theme($orion-theme);
  @include mat.legacy-checkbox-theme($orion-theme);
  @include mat.legacy-chips-theme($orion-theme);
  @include mat.legacy-core-theme($orion-theme);
  @include mat.legacy-dialog-theme($orion-theme);
  @include mat.legacy-form-field-theme($orion-theme);
  @include mat.legacy-input-theme($orion-theme);
  @include mat.legacy-list-theme($orion-theme);
  @include mat.legacy-menu-theme($orion-theme);
  @include mat.legacy-paginator-theme($orion-theme);
  @include mat.legacy-progress-bar-theme($orion-theme);
  @include mat.legacy-progress-spinner-theme($orion-theme);
  @include mat.legacy-radio-theme($orion-theme);
  @include mat.legacy-select-theme($orion-theme);
  @include mat.legacy-slide-toggle-theme($orion-theme);
  @include mat.legacy-slider-theme($orion-theme);
  @include mat.legacy-snack-bar-theme($orion-theme);
  @include mat.legacy-table-theme($orion-theme);
  @include mat.legacy-tabs-theme($orion-theme);
  @include mat.legacy-tooltip-theme($orion-theme);

  thead,
  .maintask_dashed_container_class {
    background: background-color($orion-theme, hover);
  }

  .mat-dialog-container {
    background: background-color($orion-theme, background) !important;
  }

  .mat-toolbar {
    background: header-color($orion-theme);
    color: $color-white;
  }

  .mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
    background: color-primary($orion-theme);
  }

  .mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
    background: color-opacity(color-primary($orion-theme), 0.5);
  }

  .mat-icon,
  .mat-icon.mat-primary {
    color: color-icons($orion-theme) !important;
  }

  button:disabled {
    .mat-icon {
      color: foreground-rgb-color($orion-theme, 0.3) !important;
    }
  }

  .search-admin > input,
  .search-bar,
  .search_container > input {
    background: background-search($orion-theme);
    color: inherit;
  }

  button.mat-primary,
  button.mat-warn {
    color: text-with-background($orion-theme) !important;

    span {
      color: text-with-background($orion-theme) !important;
    }

    &:disabled {
      color: foreground-rgb-color($orion-theme, 0.2) !important;

      span {
        color: foreground-rgb-color($orion-theme, 0.2) !important;
      }
    }
  }

  .basic-button {
    color: color-primary($orion-theme) !important;

    span {
      color: color-primary($orion-theme) !important;
    }
  }

  .mat-badge-content {
    color: text-with-background($orion-theme) !important;
  }

  .mat-simple-snackbar-action {
    color: color-link($orion-theme) !important;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px background-color($orion-theme, background) inset !important;
  }

  input:-webkit-autofill {
    -webkit-text-fill-color: foreground-color($orion-theme, text) !important;
  }

  // SPEC: Remove focused color on mat-tab
  .mat-tab-label,
  .mat-tab-link {
    &.cdk-keyboard-focused,
    &.cdk-program-focused {
      &:not(.mat-tab-disabled) {
        background-color: transparent !important;
      }
    }
  }

  .mat-calendar-table-header {
    background: background-search($orion-theme) !important;
    color: foreground-color($orion-theme, text) !important;
  }

  ngx-colors-panel .opened {
    background-color: background-search($orion-theme);
    color: foreground-color($orion-theme, text);

    .manual-input-wrapper {
      input,
      p {
        background-color: background-search($orion-theme);
        color: foreground-color($orion-theme, text) !important;
      }
    }

    button {
      border: solid 1px foreground-color($orion-theme, text) !important;
      color: color-primary($orion-theme) !important;
    }

    .round-button {
      fill: color-icons($orion-theme);
    }
  }

  .deteriorate-warning {
    background: $color-warn-light;
    color: $color-warn;
    font-size: 14px;
    padding: 5px;
    text-align: center;
  }
}
